import { useEffect, useState } from "react";
import Select from 'react-select';
const Tags = (props) => {
    console.log(props)
    const [editActive, setEditActive] = useState({id: 0, tagsNama: ""});
    const [statusActive, setStatusActive] = useState("");
    const [filterStatus, setFilterStatus] = useState({value: "", label: "Filter Status"});
    const [filterSearch, setFilterSearch] = useState("");
    const [activePage, setActivePage] = useState(1);
    const [totalPage, setTotalPage] = useState(0);
    const [limitPage, setLimitPage] = useState({value: "", label: "Filter Limit Data"});
    var [startFrom, setStartFrom] = useState(1);
    const [status, setStatus] = useState(
    [
        {
            value: "0", 
            label: "Inactive",
            color: "warning"
        },
        {
            value: "1", 
            label: "Active",
            color: "info"
        }
    ]);
    const listPage = []
    if(totalPage > 0){
        for(let i=1;i<=totalPage;i++){
            listPage.push(i)
        }
    }
    const [listFilterStatus, setListFilterStatus] = useState(
        [
            {
                value: "", 
                label: "Tampilkan Semua",
                color: "info"
            },
            {
                value: "0", 
                label: "Inactive",
                color: "warning"
            },
            {
                value: "1", 
                label: "Active",
                color: "info"
            }
        ]);

    const [listLimitPage, setListLimitPage] = useState(
        [
            {
                value: "all", 
                label: "Tampilkan Semua",
                color: "info"
            },
            {
                value: 5, 
                label: 5,
                color: "info"
            },
            {
                value: 10, 
                label: 10,
                color: "info"
            },
            {
                value: 25, 
                label: 25,
                color: "info"
            },
            {
                value: 50, 
                label: 50,
                color: "info"
            },
        ]
    );
    const [listData, setListData] = useState([
        {no: 1, id: 1, tagsNama: "Tags 1", status: "1"},
        {no: 2, id: 2, tagsNama: "Tags 2", status: "0"},
    ])

    const handleEdit = (item) => {
        console.log(item)
        setEditActive({id: item.id, tagsNama: item.nama})
        // console.log(status.filter((items) => items.value === item.status))
        setStatusActive(status.filter((items) => items.value === item.status)[0])
    }
    
    const handleSave = () => {
        const xhr = new XMLHttpRequest(); 
        console.log(editActive)
        const dataPost = {
            tag_id: editActive.id,
            username: "adminkiblatanime",
            status: statusActive.value,
            tag: editActive.tagsNama
        }
        var form_data = `tag_id=${dataPost.tag_id}&username=${dataPost.username}&status=${dataPost.status}&tag=${dataPost.tag}`;

        xhr.open('POST', `${process.env.REACT_APP_BASE_API}tags/edit`, true)
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.send(form_data)
        xhr.onload  = () => {
            if(xhr.status===200) {
                const res = JSON.parse(xhr.response)
                setEditActive({id: 0, tagsNama: ""})
                setStatusActive("")
                getListData()
                console.log(xhr.response)
                props.setIsLoading(false)
            }else{
                const res = JSON.parse(xhr.response)
                alert(res.message);
                return true;
            }
        }
        
    }
    
    const getListData = () => {
        const xhr = new XMLHttpRequest(); 
        var param = {
            status: filterStatus.value,
            search: filterSearch,
            page: activePage,
            limit: limitPage.value
        }
        param = new URLSearchParams(param).toString();
        xhr.open('GET', `${process.env.REACT_APP_BASE_API}tags/list?${param}`, true)
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        console.log(param)
        xhr.onload = () => {
            if(xhr.status===200) {
                const tags = JSON.parse(xhr.response)
                console.log(tags)
                setActivePage(tags.listdata.page)
                setTotalPage(tags.listdata.totalhalaman)
                console.log(activePage, totalPage)
                setStartFrom(tags.listdata.dari)
                setListData(tags.listdata.datanya)
                props.setIsLoading(false)
            }
        }
        xhr.send(null)
    }
    const handleFilter = () => {
        // getListData();
        setActivePage(1);
        // console.log(activePage,editActive, "jazz")
        // setTotalPage(1)
        setEditActive({id: 0, tagsNama: ""})
    }

    useEffect(() => {
        props.setIsLoading(true)

        getListData()
        console.log('pake useeffect')
    }, [editActive,activePage])
    // const numbers = 
    return(
        <>
            <div className="card">
                <div className="card-body">
                    <div className="row">
                        <div className="col-md-3 filter">
                        <Select
                            options={listFilterStatus}
                            onChange={(evt) => setFilterStatus(evt)}
                            value={filterStatus}
                            // defaultValue={status.filter((items) => items.value === item.status)}
                        />
                        </div>
                        <div className="col-md-3 filter">
                        <Select
                            options={listLimitPage}
                            onChange={(evt) => setLimitPage(evt)}
                            value={limitPage}
                            // defaultValue={status.filter((items) => items.value === item.status)}
                        />
                        </div>
                        <div className="col-md-3 filter">
                            <input type="text" placeholder="search" className="form-control" value={filterSearch} onChange={ (event) => setFilterSearch(event.target.value) } />
                            
                        </div>
                        <div className="col-md-3 filter"><button className="btn btn-info terapkan" type="button" onClick={handleFilter}>Terapkan</button></div>
                    </div>
                </div>
            </div>
            <div className="card mt-3">
                <div className="card-header">
                    <h3 className="card-title">Manage Tags</h3>
                </div>
                <div className="card-body">
                    <div className="table-responsive">
                    <table className="table table-bordered table-sm">
                        <thead>
                            <tr>
                                <th rowSpan="2" width="5%">No</th>
                                <th rowSpan="2" width="20%">Tags</th>
                                <th rowSpan="2" width="20%">Status</th>
                                <th colSpan="2">Aksi</th>
                            </tr>
                        </thead>
                        <tbody>
                            {listData.length > 0 ? listData.map((item, index) => (
                            <tr key={index}>
                                <td className="text-center">{startFrom++}</td>
                                <td>
                                    {editActive.id === item.id ? (
                                        <input 
                                            type="text" 
                                            className="form-control" 
                                            value={editActive.tagsNama} 
                                            
                                            onChange={(event) => {setEditActive({...editActive, tagsNama : event.target.value})}}
                                        />
                                    ) : item.nama}
                                </td>
                                <td className="text-center">
                                    {editActive.id === item.id ? (
                                        <Select
                                            options={status}
                                            onChange={(evt) => setStatusActive(evt)}
                                            value={statusActive}
                                            defaultValue={status.filter((items) => items.value === item.status)}
                                        />
                                    ) : (
                                        <div className={"badge bg-" + status.filter((items) => items.value === item.status)[0].color}>{status.filter((items) => items.value === item.status)[0].label}</div>
                                    )}
                                    
                                    
                                </td>
                                <td className="text-center">
                                    {editActive.id === item.id ? (
                                    <button type="button" className="btn btn-primary btn-sm" onClick={handleSave}>
                                        <span className="material-symbols-rounded icon align-middle">
                                        save
                                        </span>
                                    </button>
                                    ) : (
                                        <button type="button" className="btn btn-warning btn-sm" onClick={() => handleEdit(item)}>
                                            <span className="material-symbols-rounded icon align-middle">
                                            ink_pen
                                            </span>
                                        </button>
                                    )}
                                </td>
                                <td className="text-center">
                                    <button type="button" className="btn btn-danger btn-sm">
                                        <span className="material-symbols-rounded icon align-middle">
                                        restore_from_trash
                                        </span>
                                    </button>
                                </td>
                            </tr>
                            )) : ""}
                            
                        </tbody>
                    </table>
                    </div>
                    <nav aria-label="Page navigation example" className="float-end mt-3">
                        <ul className="pagination">
                            <li className={activePage > 1 ? "page-item"  : "page-item disabled"} onClick={() => setActivePage(1)}><a className="page-link">First</a></li>
                            

                            {listPage.length > 0 ? listPage.map((val, key) => (
                                <li key={key} className={activePage === val ? "page-item active" : "page-item"} onClick={() => setActivePage(val)}><a className="page-link">{val}</a></li>
                            )) : "" }
                            {/* <li className="page-item"><a className="page-link">2</a></li>
                            <li className="page-item"><a className="page-link">3</a></li> */}
                           
                            <li className={totalPage > 1 && activePage < totalPage ? "page-item"  : "page-item disabled"} onClick={() => setActivePage(totalPage)}><a className="page-link">Last</a></li>
                            
                        </ul>
                    </nav>
                </div>
            </div>
            
        </>
    );
}
export default Tags;