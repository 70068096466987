import { useEffect, useState } from "react";
import Select from 'react-select';
const SubKategori = () => {
    const [editActive, setEditActive] = useState({id: 0, subKategoriName: "", kategoriId: 0});
    const [statusActive, setStatusActive] = useState("");
    const [kategoriActive, setKategoriActive] = useState("");
    const [listKategori, setListKategori] = useState([]);

    const [filterStatus, setFilterStatus] = useState({value: "", label: "Filter Status"});
    const [filterSearch, setFilterSearch] = useState("");
    const [activePage, setActivePage] = useState(1);
    const [totalPage, setTotalPage] = useState(0);
    const [limitPage, setLimitPage] = useState({value: "", label: "Filter Limit Data"});
    var [startFrom, setStartFrom] = useState(1);

    const [status, setStatus] = useState(
    [
        {
            value: "0", 
            label: "Inactive",
            color: "warning"
        },
        {
            value: "1", 
            label: "Active",
            color: "info"
        }
    ]);
    const [listData, setListData] = useState([
        {no: 1, id: 1, tagsNama: "Tags 1", status: "1"},
        {no: 2, id: 2, tagsNama: "Tags 2", status: "0"},
    ])

    const handleEdit = (item) => {
        setEditActive({id: item.id, subKategoriName: item.nama, kategoriId : item.kategori_id})
        setStatusActive(status.filter((items) => items.value === item.status)[0])
        setKategoriActive(listKategori.filter((items) => items.value === item.kategori_id)[0])
    }
    
    const handleSave = () => {
        const xhr = new XMLHttpRequest(); 
        const dataPost = {
            sub_kategori_id: editActive.id,
            username: "adminkiblatanime",
            status: statusActive.value,
            sub_kategori: editActive.subKategoriName,
            kategori_id: kategoriActive.value
        }
        var form_data = `sub_kategori_id=${dataPost.sub_kategori_id}&username=${dataPost.username}&status=${dataPost.status}&sub_kategori=${dataPost.sub_kategori}&kategori_id=${dataPost.kategori_id}`;

        xhr.open('POST', 'http://localhost/pbn/api/sub_kategori/edit', true)
        xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
        xhr.send(form_data)
        xhr.onload  = () => {
            if(xhr.status===200) {
                const res = JSON.parse(xhr.response)
                setEditActive({id: 0, subKategoriName: "", kategoriId : 0})
                setStatusActive("")
                setKategoriActive("")
                getListData()
                // setListData(tags.listdata.datanya)
            }else{
                const res = JSON.parse(xhr.response)
                alert(res.message);
                return true;
                // alert(xhr.response.message)
            }
        }
        // xhr.onload = () => {
        //     if(xhr.status===200) {
        //         setEditActive({id: 0, tagsNama: ""})
        //         setStatusActive("")
        //     }
        // }
        
    }
    
    const getListData = () => {
        const xhr = new XMLHttpRequest(); 
        xhr.open('GET', 'http://localhost/pbn/api/sub_kategori/list', true)
        xhr.onload = () => {
            if(xhr.status===200) {
                const sub = JSON.parse(xhr.response)
                setListData(sub.listdata.datanya)
            }
        }
        xhr.send(null)
    }

    const getKategoriActive = () => {
        const xhr = new XMLHttpRequest(); 
        xhr.open('GET', 'http://localhost/pbn/api/kategori/active', true)
        xhr.onload = () => {
            if(xhr.status===200) {
                const kategori = JSON.parse(xhr.response)
                setListKategori(kategori.listdata.datanya)
            }
        }
        xhr.send(null)
    }

    const getKategoriName = (kat_id) => {
        const katName = listKategori.filter((items) => items.value === kat_id)[0]
        if(katName !== undefined){
            return Object.values(katName)[1]
        }
    } 

    useEffect(() => {
        getListData()
        getKategoriActive()
    }, [activePage, editActive])
    return(
        <>
            {/* <Layouts /> */}
            <div className="card">
                <div className="card-header">
                    <h3 className="card-title">Sub Kategori</h3>
                </div>
                <div className="card-body">
                    <table className="table table-bordered">
                        <thead>
                            <tr>
                                <th rowSpan="2" width="5%">No</th>
                                <th rowSpan="2" width="20%">Sub Kategori</th>
                                <th rowSpan="2" width="20%">Kategori</th>
                                <th rowSpan="2" width="20%">Status</th>
                                <th colSpan="2">Aksi</th>
                            </tr>
                        </thead>
                        <tbody>
                            {listData.length > 0 ? listData.map((item, index) => (
                            <tr key={index}>
                                <td className="text-center">{item.no}</td>
                                <td>
                                    {editActive.id === item.id ? (
                                        <input 
                                            type="text" 
                                            className="form-control" 
                                            value={editActive.subKategoriName} 
                                            
                                            onChange={(event) => {setEditActive({...editActive, subKategoriName : event.target.value})}}
                                        />
                                    ) : item.nama}
                                </td>
                                    {editActive.id === item.id ? (
                                    <td>
                                        <Select
                                            options={listKategori}
                                            onChange={(evt) => setKategoriActive(evt)}
                                            value={kategoriActive}
                                            defaultValue={listKategori.filter((items) => items.value === item.kategori_id)}
                                        />
                                    </td>
                                    ) : (
                                            <td className="text-center">{getKategoriName(item.kategori_id)}</td>
                                        )
                                    }
                                    
                                    
                                <td className="text-center">
                                    {editActive.id === item.id ? (
                                        <Select
                                            options={status}
                                            onChange={(evt) => setStatusActive(evt)}
                                            value={statusActive}
                                            defaultValue={status.filter((items) => items.value === item.status)}
                                        />
                                    ) : (
                                        <div className={"badge bg-" + status.filter((items) => items.value === item.status)[0].color}>{status.filter((items) => items.value === item.status)[0].label}</div>
                                    )}
                                    
                                    
                                </td>
                                <td className="text-center">
                                    {editActive.id === item.id ? (
                                    <button type="button" className="btn btn-primary btn-sm" onClick={handleSave}>
                                        <span className="material-symbols-rounded icon align-middle">
                                        save
                                        </span>
                                    </button>
                                    ) : (
                                        <button type="button" className="btn btn-warning btn-sm" onClick={() => handleEdit(item)}>
                                            <span className="material-symbols-rounded icon align-middle">
                                            ink_pen
                                            </span>
                                        </button>
                                    )}
                                </td>
                                <td className="text-center">
                                    <button type="button" className="btn btn-danger btn-sm">
                                        <span className="material-symbols-rounded icon align-middle">
                                        restore_from_trash
                                        </span>
                                    </button>
                                </td>
                            </tr>
                            )) : JSON.stringify(listData)}
                            
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
}
export default SubKategori;