

const Dashboard = () => {
    return(
        <>
            {/* <Layouts /> */}
            <h1>Dashboard</h1>
        </>
    );
}
export default Dashboard;