import { useState, useEffect } from 'react'
// import './App.css'
import Layouts from './components/Layouts'
import Dashboard from './pages/Dashboard'
import Artikel from './pages/Artikel'
import Kategori from './pages/Kategori'
import SubKategori from './pages/SubKategori'
import Tags from './pages/Tags'
// import packageJson from '/package.json';

import {
  createBrowserRouter,
  RouterProvider,
  Router,
  Link,
} from "react-router-dom";

function App() {

  const [isLoading, setIsLoading] = useState(false)
  // const [domainName, setAppName] = );
  useEffect(() => {
    if(localStorage.getItem("app_name") === null){
      //check for login
    }
    // console.log(localStorage.getItem("app_name"))
    // localStorage.getItem("app_name") == undefined
  }, [])
  const router = createBrowserRouter([
    {
      path: "/",
      element: <><Layouts><Dashboard /></Layouts></>
    },
    {
      path: "/posts",
      element: <><Layouts><Artikel /></Layouts></>
    },
    {
      path: "/kategori",
      element: <><Layouts><Kategori /></Layouts></>
    },
    {
      path: "/sub-kategori",
      element: <><Layouts><SubKategori /></Layouts></>
    },
    {
      path: "/tags",
      element: <><Layouts isLoading={isLoading}><Tags isLoading={isLoading} setIsLoading={setIsLoading} /></Layouts></>
    }
  ]);

  return (
    <RouterProvider router={router} />
  )
}

export default App
