import React from 'react'
import ReactDOM from 'react-dom/client'

import App from './App'
import 'bootstrap/dist/css/bootstrap.css';
import './assets/custom.css';
// require('dotenv').config()
ReactDOM.createRoot(document.getElementById('root')).render(

  <React.StrictMode>
    
    <App />
  </React.StrictMode>
  
)