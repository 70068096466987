
import {useState, useEffect} from 'react';
import {Link } from 'react-router-dom'
// import packageJson from '/package.json';
import React from 'react';
const Layouts = (props) => {
    console.log(props)
    const appName = import.meta.env;
    // const apiBaseUrl = import.meta.env;
    console.log(process.env)

    const [statusSidebar, setStatusSidebar] = useState(false);
    const [isMobile, setIsMobile] = useState(false)

    const closeSidebar = (e) => {
        // e.preventDefault();
        setStatusSidebar(!statusSidebar);
    }
    const showSidebar = () => {
        setStatusSidebar(true);
    }
    const handleResize = () => {
        if (window.innerWidth < 720) {
            setIsMobile(true)
        } else {
            setIsMobile(false)
        }
    }
    useEffect(() => {
        if (window.innerWidth < 720) {
            setIsMobile(true)
            setStatusSidebar(false)
        } else {
            setStatusSidebar(true)
            setIsMobile(false)
        }
        // setStatusSidebar(false)
        window.addEventListener("resize", handleResize)
    }, [])

    console.log(isMobile)
    return(
        <>
            {props.isLoading ? <div className="notAllow">You Are Not Allowed To Access This Site!</div> : ""}
            
            <nav className={isMobile ? statusSidebar ? "sidebar d-block" : "sidebar d-none" : statusSidebar ? "sidebar" : "sidebar close" }>
                <header>
                    <div className="image-text">
                        <span className="image">
                            <img src={"assets/image/Logo-Gilland-Group-Small.png"} alt="" />
                        </span>
                        <div className="text header-text">
                            <span className="title">{process.env.REACT_APP_NAME}</span>
                        </div>
                    </div>
                    <div className="close-btn" id="close-btn" onClick={(e) => closeSidebar(e)}>
                        <span className="material-symbols-rounded">
                            close
                        </span>
                    </div>
                    <div className="toggle" onClick={(e) => closeSidebar(e)}>
                        <span className="material-symbols-rounded toggle-nav">
                            chevron_right
                        </span>
                    </div>
                </header>

                <div className="menu-bar">
                    <div className="menu">
                        <li className="nav-link-cus">
                            <Link to={"/"} className={window.location.pathname === "/" ? "active" : ""}> 
                                <span className="material-symbols-rounded icon">
                                    dashboard
                                </span>
                                <span className="text nav-text">Dashboard</span>
                            </Link>
                        </li>
                        <li className="nav-link-cus">
                            <Link to={"/posts"} className={window.location.pathname === "/posts" ? "active" : ""}>
                                <span className="material-symbols-rounded icon">
                                    prescriptions
                                </span>
                                <span className="text nav-text">Artikel</span>
                            </Link>
                        </li>
                        <li className="nav-link-cus">
                            <Link to={"/kategori"} className={window.location.pathname === "/kategori" ? "active" : ""}> 
                                <span className="material-symbols-rounded icon">
                                    category
                                </span>
                                <span className="text nav-text">Kategori</span>
                            </Link>
                            
                        </li>
                        <li className="nav-link-cus">
                            <Link to={"/sub-kategori"} className={window.location.pathname === "/sub-kategori" ? "active" : ""}> 
                                <span className="material-symbols-rounded icon">
                                subdirectory_arrow_right
                                </span>
                                <span className="text nav-text">Sub Kategori</span>
                            </Link>
                        </li>
                        <li className="nav-link-cus">
                            <Link to={"/tags"} className={window.location.pathname === "/tags" ? "active" : ""}> 
                                <span className="material-symbols-rounded icon">
                                    numbers
                                </span>
                                <span className="text nav-text">Tags</span>
                            </Link>
                        </li>
                    </div>

                    <div className="bottom-content">
                        <li className="">
                            <a href="<?= base_url('logout') ?>">
                                <span className="material-symbols-rounded icon">
                                    logout
                                </span>
                                <span className="text nav-text">Logout</span>
                            </a>
                        </li>
                    </div>
                </div>
            </nav>
            <section className="topbar-container">
                <div className="top-bar">
                    <button id="menu-btn">
                        <span className="material-symbols-rounded" onClick={(e) => closeSidebar(e)}>
                            menu
                        </span>
                    </button>
                    <div style={{width: "25%" }} className="me-auto ms-3 py-2 d-flex align-items-center gap-2">
                        <div className="fw-bold font-small" id="dateContainer"></div>
                        <div style={{width: "32px"}} className="position-relative">
                        </div>
                    </div>
                </div>
            </section>
            <section className="home">
                <div className="bg-light" style={{ minHeight: "100%" }}>
                    <div className="spacer-64"></div>
                    <div className="p-2">
                        {props.children}
                    </div>
                </div>
            </section>
        </>
    );
}

export default Layouts;